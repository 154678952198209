import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs';
import { previewApplicant } from './../../../dashboard/main/main.selectors';
import { MainState } from './../../../dashboard/main/redusers/main.reducer';
import { DashboardService } from './../../../dashboard/dashboard.service';
import { ApplicantFile } from './../../../interfaces/applicant.interface';
import { Applicant } from './../../../interfaces/applicant.interface';

export interface PreviewFileEntity {
  fileEntity: 'applicant' | 'candidate';
  candidateId?: string;
}

@Component({
  selector: 'app-preview-file-modal',
  templateUrl: './preview-file-modal.component.html',
  styleUrls: ['./preview-file-modal.component.scss']
})
export class PreviewFileModalComponent implements OnInit {
  previewApplicant: Applicant;

  previewFile: ApplicantFile & PreviewFileEntity;
  previewLoading = false;
  fileType: string; // image | pdf | doc

  constructor(
    private store: Store<MainState>,
    private dashboardServices: DashboardService,
    public dialogRef: MatDialogRef<PreviewFileModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ApplicantFile & PreviewFileEntity
    ) { }

  ngOnInit(): void {
    this.store.pipe(
        select(previewApplicant),
        first()
      )
      .subscribe(a => this.previewApplicant = a);

    this.loadFile();
  }

  loadFile() {
    this.previewFile = this.data;
    this.previewLoading = true;
    this.setFileType(this.data);
  }

  setFileType(file: ApplicantFile) {
    if (file) {
      if (file.mimetype.split('/')[0] === 'image') {
        this.previewLoading = false;
        return this.fileType = 'image';
      } else if (file.mimetype.split('/')[1] === 'pdf') {
        return this.fileType = 'pdf';
      } else if (file.mimetype.split('/')[1] === 'msword' || file.mimetype.split('/')[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return this.fileType = 'doc';
      }
    }
  }

  onDownloadFile() {
    const entityId = this.previewFile.fileEntity === 'applicant' ? this.previewApplicant._id : this.previewFile.candidateId;
    this.dashboardServices.downloadFile(entityId, this.previewFile);
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
