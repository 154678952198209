import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceWorkerService } from 'src/app/services/service-worker/service-worker.service';

@Component({
  selector: 'app-update-app',
  templateUrl: './update-app.component.html',
  styleUrls: ['./update-app.component.scss']
})
export class UpdateAppComponent implements OnInit {

  newVersion$: Observable<boolean> | undefined;

  constructor(private serviceWorkerService: ServiceWorkerService) {}

  ngOnInit(): void {
    this.newVersion$ = this.serviceWorkerService.newVersion$;
  }

  onUpdate() {
    location.reload();
  }

}
