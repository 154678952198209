import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApplicantFile } from './../interfaces/applicant.interface';
import { Email } from '../interfaces/email.interface';
import { PhoneNumber } from './admin/phone/interfaces/phone-number.interface';
import { SelectClientComponent } from './components/select-client/select-client.component';
import { PreviewFileEntity } from './../shared/components/preview-file-modal/preview-file-modal.component';

@Injectable({providedIn: 'root'})
export class DashboardService {

  closeEditForms = new Subject<void>();
  tabSelected = new Subject<any>();
  updateEmailListTable = new Subject<{scheduled: Email[], sent: Email[]}>();
  previewFile$ = new BehaviorSubject<ApplicantFile>(undefined);
  profileFilePreviewModeInMain$ = new Subject<'main' | 'modal'>();
  chatTab$ = new Subject<boolean>();
  selectApplicantPreviewTab$ = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    ) {}

  getDashboardSettings() {
    return this.http.get(environment.serverURL + '/api/user/dashboard-settings');
  }

  toggleDashboardNav(navState: boolean) {
    return this.http.post(environment.serverURL + '/api/user/dashboard-nav-toggle', {navState});
  }

  toggleDashboardPreviewWindow(previewState: boolean) {
    return this.http.post(environment.serverURL + '/api/user/dashboard-preview-toggle', {previewState});
  }

  selectClientDialog() {
    const dialogRef = this.dialog.open(SelectClientComponent, {disableClose: true, minWidth: '400px'});
    return dialogRef.afterClosed();
  }

  getEmailTemplatesAndAccounts(clientId: string, clientTerritoryId: string) {
    const params = new HttpParams().append('clientId', clientId).append('clientTerritoryId', clientTerritoryId);
    return this.http.get(environment.serverURL + '/api/email/new', {params});
  }

  sendEmail(email, opt?: {isBulk?: boolean}) {
    const params = opt ? new HttpParams().set('isBulk', opt.isBulk.toString()) : null;
    return this.http.post<Email | {successEmails: number}>(environment.serverURL + '/api/email/new', email, {params});
  }

  sendText(smsMessage, opt?: {isBulk?: boolean}) {
    const params = opt ? new HttpParams().set('isBulk', opt.isBulk.toString()) : null;
    return this.http.post<{smsMessages: number}>(environment.serverURL + '/api/phone/sms/new', smsMessage, {params});
  }

  getApplicantEmails(applicantId) {
    const params = new HttpParams().set('applicantId', applicantId);
    return this.http.get<{scheduled: Email[], sent: Email[]}>(environment.serverURL + '/api/email', {params});
  }

  deleteScheduledEmail(id: string) {
    return this.http.delete(environment.serverURL + '/api/email/' + id);
  }

  getFileLink(applicantId: string, fileId: string) {
    return this.http.get<ApplicantFile>(environment.serverURL + '/api/applicant/' + applicantId + '/file/' + fileId);
  }

  downloadFile(applicantId: string, file: ApplicantFile & PreviewFileEntity) {
    this.http.get(environment.serverURL + '/api/' + file.fileEntity + '/'  + applicantId +  '/file/' + file._id + '/download', { responseType: 'blob' })
      .pipe(take(1))
      .subscribe(res => {
        const blob = new Blob([res], { type: file.mimetype });
        saveAs(blob, file.name);
      });
  }

  deleteFile(applicantId: string, file: ApplicantFile) {
    return this.http.delete(environment.serverURL + '/api/applicant/' + applicantId + '/file/' + file._id);
  }

  uploadFile(file: File, applicantId: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const req = new HttpRequest('POST', `${environment.serverURL}/api/applicant/${applicantId}/file`, formData, {responseType: 'json'});
    return this.http.request(req);
  }

  getUserPhoneNumber() {
    return this.http.get<PhoneNumber[]>(environment.serverURL + '/api/user-phone-number');
  }

  getTwilioToken(userId: string) {
    const params = new HttpParams().append('identity', userId);
    return this.http.get<{token: string}>(environment.serverURL + '/api/conversation/token', {params})
      .pipe(map(tokenObj => tokenObj.token));
  }

}
