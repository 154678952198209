import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './../material.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { PhoneFormatPipe } from './pipes/phone.pipe';
import { EllipsisPipe } from './pipes/elipsis.pipe';

import { ErrorComponent } from './error/error.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalLoaderComponent } from './components/modal-loader/modal-loader.component';
import { MatBadgeIconDirective } from './directives/badge-icon.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { AppScrollListenerDirective } from './directives/app-scroll-listener.directive';
import { PreviewFileModalComponent } from './components/preview-file-modal/preview-file-modal.component';
import { ModalLicenseErrComponent } from './components/modal-license-err/modal-license-err.component';
import { UpdateAppComponent } from './components/update-app/update-app.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    NgxDocViewerModule,
    PdfViewerModule,
    PickerModule,
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    PickerModule,
    MatBadgeIconDirective,
    PhoneMaskDirective,
    AppScrollListenerDirective,
    PhoneFormatPipe,
    EllipsisPipe,
    UpdateAppComponent
  ],
  declarations: [
    PhoneFormatPipe,
    EllipsisPipe,
    ErrorComponent,
    ModalComponent,
    ModalLoaderComponent,
    MatBadgeIconDirective,
    PhoneMaskDirective,
    PreviewFileModalComponent,
    ModalLicenseErrComponent,
    AppScrollListenerDirective,
    UpdateAppComponent
  ],
  providers: [
    DecimalPipe
  ]
})

export class SharedModule {}
