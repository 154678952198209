import { User } from '../interfaces/user.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) { }

  login(email: string, password: string) {
    return this.http.post<User>(environment.serverURL + '/api/auth/login', {email, password});
  }

  saveData(token): User | null {
    localStorage.setItem('token', token);
    const tokenPayload = this.getDecodedAccessToken(token);
    if (tokenPayload) {
        delete tokenPayload.iat;
        delete tokenPayload.exp;
        localStorage.setItem('user', JSON.stringify(tokenPayload));
        return tokenPayload;
    }
    return tokenPayload;

  }

  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUserForActivation(userId: string) {
    return this.http.get<User>(environment.serverURL + '/api/auth/new-user/' + userId);
  }

  activateUser(user) {
    return this.http.post<{token: string}>(environment.serverURL + '/api/auth/user/', {user});
  }

  resetPassword(password, token: string) {
    return this.http.post(environment.serverURL + '/api/auth/reset-password/', {password, token});
  }

  forgotPassword(email: string) {
    const params = new HttpParams().append('email', email);
    return this.http.get(environment.serverURL + '/api/auth/forgot-password/', {params});
  }

}
