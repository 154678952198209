import { EntityMetadataMap, EntityDataModuleConfig } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
  applicant: {}
};

const pluralNames = {
  status: 'statuses',
  emailScheduled: 'emailsScheduled',
  emailSent: 'emailsSent',
};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames,
};
