import { createAction, props } from '@ngrx/store';
import { Stripe } from 'stripe';

export const loadSubscriptions = createAction(
  '[Subscription] Load Subscriptions'
);

export const loadSubscriptionsSuccess = createAction(
  '[Subscription] Load Subscriptions Success',
  props<{ subscription: Stripe.Subscription }>()
);

export const loadUpcomingInvoiceSuccess = createAction(
  '[Subscription] Load UpcomingInvoice Success',
  props<{ upcomingInvoice: Stripe.Invoice }>()
);

export const setStripeCustomerId = createAction(
  '[Subscription] Set StripeCustomerId Success',
  props<{stripeCustomerId: string}>()
);

export const loadCustomer = createAction(
  '[Subscription] Load StripeCustomer',
  props<{customerId: string}>()
);

export const setCustomer = createAction(
  '[Subscription] Set StripeCustomer Success',
  props<{customer: Stripe.Customer}>()
);

export const loadSubscriptionsFailure = createAction(
  '[Subscription] Load Subscriptions Failure',
  props<{ error: any }>()
);

export const updateDefaultPaymentMethodInSubscription = createAction(
  '[Subscription] Set Default Payment Method on Subscription',
  props<{ paymentMethod: Stripe.PaymentMethod }>()
);

export const loadInvoices = createAction(
  '[Invoices] Load Invoices'
);

export const loadInvoicesSuccess = createAction(
  '[Invoices] Load Invoices Success',
  props<{ invoices: Stripe.Invoice[], has_more: boolean, isLoaded: boolean }>()
);
