import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { AuthActions } from './action-types';
import { DashboardActions } from './../dashboard/dashboard.action-types';
import { MainActions } from './../dashboard/main/main.action-types';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of } from 'rxjs';

import { AuthService } from './auth.service';
import { ChatService } from '../chat/chat.service';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.login),
        exhaustMap(action => {
          this.sharedService.globalLoaderState.next(true);
          return this.authService.login(action.email, action.password)
            .pipe(
              map((res: any) => {
                const user = this.authService.saveData(res.token);
                return AuthActions.loginSuccess({user});
              }),
              catchError(error => {
                return of(AuthActions.loginFailure({error}));
              })
            );
        })
      )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.loginSuccess),
        map((action) => {
            this.sharedService.globalLoaderState.next(false);
            if (window.location.pathname === '/login') {
              this.router.navigateByUrl('/dashboard');
            }
        })
    ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.logout),
        map(() => {
          this.router.navigateByUrl('/login');
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          localStorage.removeItem('client');
          localStorage.removeItem('selectedTerritoryId');
          this.chatService.shutdown();
          this.store.dispatch(MainActions.clearMainSettings());
          return DashboardActions.clearSettings();
        })
      )
    );

    loginFailure$ = createEffect(() =>
    this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        map(res => AuthActions.errorLogout())
    )
);

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private sharedService: SharedService,
    private chatService: ChatService,
    private store: Store<AppState>
  ) {}

}
