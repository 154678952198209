import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from './reducers/index';
import { AuthActions } from './auth/action-types';
import { SharedService } from './shared/shared.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { user } from './auth/auth.selectors';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  globalLoaderSub: Subscription;
  permissionsSub: Subscription;

  globalLoader = false;

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    private permissionsService: NgxPermissionsService
  ) {}

  ngOnInit() {
    const userProfile = localStorage.getItem('user');
    if (userProfile) {
      this.store.dispatch(AuthActions.loginSuccess({user: JSON.parse(userProfile)}));
    }

    // Set User permissions
    this.permissionsSub = this.store
      .pipe(
        select(user),
        filter(u => !!u),
        map(u => u.role),
        tap(role => this.permissionsService.loadPermissions([role]))
      )
      .subscribe();

    this.globalLoaderSub = this.sharedService.globalLoaderState.subscribe(res => this.globalLoader = res);

  }

  ngOnDestroy() {
    this.globalLoaderSub.unsubscribe();
    this.permissionsSub.unsubscribe();
  }

}
