import { AuthState } from './reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const isLoggedIn = createSelector(
  selectAuthState,
  state => !!state.user
);

export const isLoggedOut = createSelector(
  isLoggedIn,
  state => !state
);

export const fullName = createSelector(
  selectAuthState,
  state => `${state.user ? state.user.firstName + ' ' + state.user.lastName : ''}`
);

export const myUserId = createSelector(
  selectAuthState,
  state => state.user ? state.user._id : null
);

export const user = createSelector(
  selectAuthState,
  state => state.user ? state.user : undefined
);
