import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

export class ClosedConversations {

  constructor(private conversationSid: string, private http: HttpClient) {}

  async getMessages(messagesPerPage: number): Promise<any> {
    try {
      const conversationMessages = await this.http.get<any[]>(environment.serverURL + '/api/conversation/' + this.conversationSid + '/messages').toPromise();
      return new MessagesPaginator(conversationMessages, messagesPerPage);
    } catch (error) {
      throw error;
    }
  }

  get sid() {
    return this.conversationSid;
  }
}

class MessagesPaginator {

  constructor(private messages: any[], private messagesPerPage: number, private index = 0) {}

  get items() {
    const returnStart = this.index;
    const returnEnd = this.messages.length > this.messagesPerPage + this.index ? this.messagesPerPage + this.index : this.messages.length;

    this.index = returnEnd;

    return this.messages.slice(returnStart, returnEnd);
  }

  get hasPrevPage() {
    return this.messages.length > this.index + 1 ? true : false;
  }

  prevPage() {
    if (this.hasPrevPage) {
      return new MessagesPaginator(this.messages, this.messagesPerPage, this.index);
    }
    return null;
  }

}
