import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule, DefaultDataServiceConfig, PersistenceResultHandler, EntityCollectionReducerMethodsFactory } from '@ngrx/data';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { IntegrationsInterceptor } from './interceptors/integrations.interceptor';
import { AuthModule } from './auth/auth.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { reducers, metaReducers } from './reducers';
import { AuthGuard } from './guards/auth.guard';
import { AppEffects } from './app.effects';
import { AdditionalEntityCollectionReducerMethodsFactory } from './store/additional-entity-collection.factory';
import { AdditionalPersistenceResultHandler } from './store/additional-persistence-result.handler';
import { entityConfig } from './entity-metadata';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.serverURL + '/api/'
};

const interceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: IntegrationsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    AuthModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AppEffects]),
    EntityDataModule.forRoot(entityConfig),
    NgxPermissionsModule.forRoot(),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      scope: '/dashboard',
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    })
  ],
  providers: [
    AuthGuard,
    interceptors,
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    { provide: PersistenceResultHandler, useClass: AdditionalPersistenceResultHandler },
    { provide: EntityCollectionReducerMethodsFactory, useClass: AdditionalEntityCollectionReducerMethodsFactory },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
