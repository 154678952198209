import { EntityCollectionReducerMethods, EntityDefinition, EntityCollection, EntityAction } from '@ngrx/data';

export class AdditionalEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
  constructor(public entityName: string, public definition: EntityDefinition<T>) {
    super(entityName, definition);
  }

  protected queryManySuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const ec = super.queryManySuccess(collection, action);
    if ((action.payload as any).clientId) {
      (ec as any).clientId = (action.payload as any).clientId;
    }
    if ((action.payload as any).clientTerritoryId) {
      (ec as any).clientTerritoryId = (action.payload as any).clientTerritoryId;
    } else {
      (ec as any).clientTerritoryId = undefined;
    }
    if ((action.payload as any).hasNextPage !== undefined) {
      (ec as any).hasNextPage = (action.payload as any).hasNextPage;
    }
    return ec;
  }

  protected removeAll(collection: EntityCollection<T>, action: EntityAction<T>) {
    const ec = super.removeAll(collection, action);
    (ec as any).clientTerritoryId = undefined;
    (ec as any).clientId = undefined;
    return ec;
  }

}
