import { Action, createReducer, on } from '@ngrx/store';
import { DashboardActions } from './dashboard.action-types';
import { DashboardSettings } from './../interfaces/dashboard-settings.interface';
import { Client } from './../interfaces/client.interface';

export const dashboardFeatureKey = 'dashboard';

export interface DashboardState {
  settings: DashboardSettings;
  client: Client;
}

export const initialState: DashboardState = {
  settings: undefined,
  client: undefined,
};

export const dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.loadDashboardSuccess, ((state, action) => ({...state, settings: action.settings}))),
  on(DashboardActions.clearSettings, () => ({settings: undefined, client: undefined})),
  on(DashboardActions.dashboardNavToggle, ((state) =>
    ({...state, settings: {...state.settings, navOpen: !state.settings.navOpen}}))),
  on(DashboardActions.dashboardPreviewToggle, ((state) =>
    ({...state, settings: {...state.settings, previewOpen: !state.settings.previewOpen}}))),
  on(DashboardActions.selectClientSuccess, ((state, action) => ({...state, client: action.client}))),
  on(DashboardActions.setEmailAccountsAndTemplates, ((state, action) => ({...state, client: {...state.client, emails: action.emails}}))),
  on(DashboardActions.setEmailAccountsAndTemplatesLoaded, (state) => ({...state, client: {...state.client, emailsLoaded: true}})),
  on(DashboardActions.updateAvatar, (state, action) => ({...state, settings: {...state.settings, avatar: action.avatar}})),
  on(DashboardActions.updateEmailSignatures, (state, action) =>
    ({...state, settings: {...state.settings, emailSignatures: action.emailSignatures}})),
  on(DashboardActions.updateCalendarsFilterSuccess, (state, action) =>
    ({...state, settings: {...state.settings, calendar: {...state.settings.calendar, selectedCalendars: action.calendars}}})),
  on(DashboardActions.calendarWeekendsToggleSuccess, (state, action) =>
    ({...state, settings: {...state.settings, calendar: {...state.settings.calendar, weekends: action.weekends}}})),
  on(DashboardActions.addIntegration, (state, action) => ({...state, settings: {...state.settings, integrations: [...state.settings.integrations, action.integration]}})),
  on(DashboardActions.removeIntegrationSuccess, (state, action) => ({...state, settings: {...state.settings, integrations: action.integrations}}))
);

export function reducer(state: DashboardState | undefined, action: Action) {
  return dashboardReducer(state, action);
}
