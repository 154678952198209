import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ErrorData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ErrorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public error: ErrorData) { }

  ngOnInit() {
  }

  onClick() {
    this.dialogRef.close();
  }

}
