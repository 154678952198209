import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'phoneFormat'})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    const areaCode = value.slice(0, 3);
    const threeDigits = value.slice(3, 6);
    const fourDigits = value.slice(6, 10);
    const output = `(${areaCode}) ${threeDigits}-${fourDigits}`;
    return output;
  }
}
