import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import * as fromMain from '../dashboard/main/redusers/main.reducer';
import * as fromBilling from '../dashboard/billing/store/billing.reducer';
import * as fromPhone from './../dashboard/store/phone/phone.reducer';
import * as fromDashboard from './../dashboard/dashboard.reducer';
import { environment } from '../../environments/environment';
import { AuthActions } from '../auth/action-types';

export interface AppState {
  main: fromMain.MainState;
  billing: fromBilling.State;
  phone: fromPhone.PhoneState;
  dashboard: fromDashboard.DashboardState;
}

export const reducers: ActionReducerMap<AppState> = {
  main: fromMain.reducer,
  billing: fromBilling.reducer,
  phone: fromPhone.reducer,
  dashboard: fromDashboard.reducer,
};

export const clearState = (reducer) => {
  return (state, action) => {
    if (action.type === AuthActions.logout.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
};

export const metaReducers: MetaReducer<AppState>[] = [clearState];
