import { Action, createReducer, on } from '@ngrx/store';
import { BillingActions } from './billing.action-types';
import { Stripe } from 'stripe';

export const billingFeatureKey = 'billing';

interface InvoiceList {
  isLoaded: boolean;
  has_more: boolean;
  invoices: Stripe.Invoice[];
}

export interface State {
  subscription: Stripe.Subscription;
  upcomingInvoice: Stripe.Invoice;
  customer: Stripe.Customer;
  invoiceList: InvoiceList;
  stripeCustomerId: string;
}

export const initialState: State = {
  subscription: undefined,
  upcomingInvoice: undefined,
  customer: undefined,
  invoiceList: {
    isLoaded: false,
    has_more: undefined,
    invoices: undefined,
  },
  stripeCustomerId: undefined,
};

export const billingReducer = createReducer(
  initialState,
  on(BillingActions.loadSubscriptionsSuccess, (state, action) => ({...state, subscription: action.subscription})),
  on(BillingActions.setStripeCustomerId, (state, action) => ({...state, stripeCustomerId: action.stripeCustomerId})),
  on(BillingActions.setCustomer, (state, action) => ({...state, customer: action.customer})),
  on(BillingActions.loadSubscriptionsFailure, (state) => ({...state, subscription: undefined})),
  on(BillingActions.loadUpcomingInvoiceSuccess, (state, action) => ({...state, upcomingInvoice: action.upcomingInvoice})),
  on(BillingActions.loadInvoicesSuccess, (state, action) => ({...state, invoiceList: {isLoaded: action.isLoaded, has_more: action.has_more, invoices: action.invoices }}))
);

export function reducer(state: State | undefined, action: Action) {
  return billingReducer(state, action);
}
