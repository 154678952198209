import { createAction, props } from '@ngrx/store';
import { Applicant } from './../../interfaces/applicant.interface';

export const dashboardLayoutSuccess = createAction(
  '[Main Layout Success] Layout Array',
  props<{layout: string[]}>()
);

export const updateDashboardLayout = createAction(
  '[Main Layout] Update Layout',
  props<{layout: string[]}>()
);

export const previewApplicant = createAction(
  '[Main Layout Preview] Preview',
  props<{applicant: Applicant}>()
);

export const closePreviewApplicant = createAction(
  '[Main Layout Preview] Close Preview'
);

export const clearMainSettings = createAction(
  '[Logout] Clear Main Settings'
);
