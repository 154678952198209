import { Action, createReducer, on } from '@ngrx/store';
import { MainActions } from '../main.action-types';
import { Applicant } from './../../../interfaces/applicant.interface';

export const mainFeatureKey = 'main';

export interface MainState {
  layout: string[];
  preview: Applicant;
}

export const initialDashboardState: MainState = {
  layout: undefined,
  preview: undefined
};

const mainReducer = createReducer(
  initialDashboardState,
  on(MainActions.dashboardLayoutSuccess, (state, action) => ({...state, layout: action.layout})),
  on(MainActions.clearMainSettings, () => ({layout: undefined, preview: undefined})),
  on(MainActions.previewApplicant, (state, action) => ({...state, preview: action.applicant})),
  on(MainActions.closePreviewApplicant, (state) => ({...state, preview: undefined}))
);

export function reducer(state: MainState | undefined, action: Action) {
  return mainReducer(state, action);
}
