import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appMatBadgeIcon]'
})
export class MatBadgeIconDirective implements OnInit {

  @Input() appMatBadgeIcon: string;
  @Input() appMatBadgeIconSize = '14';

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const badge = this.el.nativeElement.querySelector('.mat-badge-content');
    badge.style.display = 'flex';
    badge.style.alignItems = 'center';
    badge.style.justifyContent = 'center';
    badge.innerHTML = `
      <i class="material-icons" style="font-size: ${this.appMatBadgeIconSize}px; color: white">${this.appMatBadgeIcon}</i>
    `;
  }
}
