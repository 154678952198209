import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from './dashboard.reducer';

export const selectDashboardState = createFeatureSelector<DashboardState>('dashboard');

export const dashboardSettings = createSelector(
  selectDashboardState,
  state => state.settings
);

export const navMenu = createSelector(
  dashboardSettings,
  state => state ? state.navOpen : false
);

export const previewMenu = createSelector(
  dashboardSettings,
  state => state ? state.previewOpen : undefined
);

export const selectedClient = createSelector(
  selectDashboardState,
  state => state ? state.client : undefined
);

export const selectedClientEmails = createSelector(
  selectedClient,
  client => client ? client.emails : undefined
);

export const avatar = createSelector(
  dashboardSettings,
  state => state ? state.avatar : undefined
);

export const emailSignatures = createSelector(
  dashboardSettings,
  state => state ? state.emailSignatures : undefined
);

export const calendarSettings = createSelector(
  dashboardSettings,
  state => state ? state.calendar : undefined
);

export const calendarWeekends = createSelector(
  calendarSettings,
  settings => settings ? settings.weekends : undefined
);

export const userIntegrations = createSelector(
  dashboardSettings,
  state => state ? state.integrations : undefined
);
