import { MainState } from './redusers/main.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectMainState = createFeatureSelector<MainState>('main');

export const mainLayout = createSelector(
  selectMainState,
  state => state ? state.layout : null
);

export const previewApplicant = createSelector(
  selectMainState,
  state => state ? state.preview : null
);
