import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface LicenseErrorData {
  license: 'user' | 'company' | 'territory' | 'phone';
  error: string;
  errorRedirect?: boolean;
}

@Component({
  selector: 'app-modal-license-err',
  templateUrl: './modal-license-err.component.html',
  styleUrls: ['./modal-license-err.component.scss']
})
export class ModalLicenseErrComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LicenseErrorData,
    private dialogRef: MatDialogRef<ModalLicenseErrComponent>,
    private router: Router,
    private location: Location
    ) {}

  ngOnInit(): void {
  }

  onUpdateLicense() {
    this.router.navigate(['/dashboard/billing/subscription'], {fragment: this.data.license});
    this.dialogRef.close();
  }

  onCancel() {
    if (this.data.errorRedirect) {
      this.location.back();
    }
    this.dialogRef.close();
  }

}
