import { Injectable } from '@angular/core';
import { DefaultPersistenceResultHandler, EntityAction } from '@ngrx/data';
import { Action } from '@ngrx/store';

@Injectable()
export class AdditionalPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);
    // return a factory to get a data handler to
    // parse data from DataService and save to action.payload
    return function(data: any) {
      const action = actionHandler.call(this, data.entities ? data.entities : data);
      if (action && data && data.clientId) {
        (action as any).payload.clientId = data.clientId;
      }
      if (action && data && data.clientTerritoryId) {
        (action as any).payload.clientTerritoryId = data.clientTerritoryId;
      }
      if (action && data && data.hasNextPage !== undefined) {
        (action as any).payload.hasNextPage = data.hasNextPage;
      }
      return action;
    };
  }
}
