import { createAction, props } from '@ngrx/store';
import { Client } from './../interfaces/client.interface';
import { EmailTemplate } from './../interfaces/email-templates.interface';
import { EmailAccount } from './../interfaces/email-acount.interface';
import { DashboardSettings } from '../interfaces/dashboard-settings.interface';
import { EmailSignature } from './../interfaces/user.model';
import { Avatar } from './../interfaces/dashboard-settings.interface';

export enum CalendarOptions {
  add = 'add',
  remove = 'remove',
}

export const loadDashboard = createAction(
  '[Dashboard] Load Dashboards Settings'
);

export const loadDashboardSuccess = createAction(
  '[Dashboard] Load Dashboards Settings Success',
  props<{ settings: DashboardSettings }>()
);

export const dashboardNavToggle = createAction(
  '[Dashboard] Dashboards Nav Toggle'
);

export const dashboardPreviewToggle = createAction(
  '[Main] Dashboards Preview Toggle'
);

export const selectClient = createAction(
  '[Client] Client Selected',
  props<{client: Client}>()
);

export const loadClient = createAction(
  '[Client] Load Client',
  props<{clientId: string}>()
);

export const selectClientSuccess = createAction(
  '[Dashboard Effects] Client Selected Success',
  props<{client: Client}>()
);

export const setEmailAccountsAndTemplates = createAction(
  '[Email Tab] Add Email Accounts And Templates',
  props<{emails: {emailAccounts: EmailAccount[], emailTemplates: EmailTemplate[]}}>()
);

export const setEmailAccountsAndTemplatesLoaded = createAction(
  '[Main Mass Emails] Email Accounts And Templates Loaded',
);

export const addEmailAccountToClient = createAction(
  '[Email Account] Add Email Account to Client',
  props<{emailAccount: EmailAccount}>()
);

export const addEmailTemplateToClient = createAction(
  '[Email Template] Add Email Template to Client',
  props<{emailTemplate: EmailTemplate}>()
);

export const removeEmailTemplateOrAccountFromClient = createAction(
  '[Email Template or Account] Remove Email Template or Account',
  props<{name: 'emailAccounts' | 'emailTemplates', _id: string}>()
);

export const updateAvatar = createAction(
  '[User Settings] Update Avatar',
  props<{avatar: {type: Avatar, id?: string}}>()
);

export const clearSettings = createAction(
  '[Logout] Clear Dashboard Settings'
);

export const updateEmailSignatures = createAction(
  '[User Details] Signatures Updated',
  props<{emailSignatures: EmailSignature[]}>()
);

export const updateCalendarsFilter = createAction(
  '[Calendar] Update Calendar Filter',
  props<{calendarId: string, action: CalendarOptions}>()
);

export const updateCalendarsFilterSuccess = createAction(
  '[Calendar] Update Calendar Filter Success',
  props<{calendars: string[]}>()
);

export const calendarWeekendsToggle = createAction(
  '[Calendar] Toggle Weekends',
  props<{weekends: boolean}>()
);

export const calendarWeekendsToggleSuccess = createAction(
  '[Calendar] Toggle Weekends Success',
  props<{weekends: boolean}>()
);

export const addIntegration = createAction(
  '[Profile] Integration Added',
  props<{integration: string}>()
);

export const removeIntegration = createAction(
  '[Profile] Integration Removing...',
  props<{integrations: string[]}>()
);

export const removeIntegrationSuccess = createAction(
  '[Dashboards Effects] Integration Removed Success',
  props<{integrations: string[]}>()
);
