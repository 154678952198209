import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { map, catchError } from 'rxjs/operators';
import { AuthState } from './../auth/reducers/index';
import { SharedService } from './../shared/shared.service';
import { ErrorService } from './../shared/error.service';
import { AuthService } from './../auth/auth.service';
import { AuthActions } from '../auth/action-types';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private store: Store<AuthState>,
    private sharedService: SharedService,
    private errorService: ErrorService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

      const token = this.authService.getToken();
      let modifiedRequest;

      if (token) {
        modifiedRequest = req.clone({headers: new HttpHeaders().set('Authorization', token)});
      } else {
        modifiedRequest = req;
      }

      return next.handle(modifiedRequest)
        .pipe(
          map(evt => {
            if (evt instanceof HttpResponse) {
              if (evt.headers.get('Authorization')) {
                const newToken = evt.headers.get('Authorization');
                const updatedUser = this.authService.saveData(newToken);
                this.store.dispatch(AuthActions.updateUser({user: updatedUser}));
              }
            }
            return evt;
          }),
          catchError((error: HttpErrorResponse) => {
            this.sharedService.globalLoaderState.next(false);
            if (error.status === 401) {
              this.errorService.showErrorMessage(error.error);
              this.store.dispatch(AuthActions.logout());
              throw error;
            } else if (error.error && error.error.title === 'Licensing Error') { // License error handling
              this.sharedService.licenseError({license: error.error.license, error: error.error.error, errorRedirect: error.error.errorRedirect});
              throw error;
            } else {
              this.errorService.showErrorMessage(error.error);
              throw error;
            }
          })
        );
    }
}
