import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { map, take, tap } from 'rxjs/operators';
import { SmsMessage } from './../../interfaces/sms-message.interface';
import { sortBy } from 'lodash';
import * as moment from 'moment-timezone';

export class ChatSmsMessages {

  private allSmsMessages = [];

  constructor(private applicantId: string, private http: HttpClient) {}

  get messags() {
    return this.sortMessages(this.allSmsMessages.slice());
  }

  loadAllMessages() {
    const params = new HttpParams().append('applicantId', this.applicantId);
    return this.http.get<SmsMessage[]>(environment.serverURL + '/api/phone/sms', {params})
      .pipe(take(1), map(messages => messages.map(m => this.formatMessage(m))), tap(messages => this.allSmsMessages = messages))
      .toPromise();
  }

  formatMessage(message: SmsMessage) {
    return {
      id: message._id,
      messageSid: message.messageSid,
      isScheduled: message.isScheduled,
      schedule: message.schedule,
      status: message.status,
      isSent: message.isSent,
      error: message.error,
      body: message.body,
      dateCreated: message.createdAt,
      dateSent: message.dateSent,
      direction: 'outgoing',
      author: message.createdBy,
    };
  }

  mergeMessages(messages: any[], lastLoadedMessage = null, getAllMessages = false) {
    let smsMessages: any[] = [];

    if (!getAllMessages) {
      // Sms messages after first conversations message
      smsMessages = this.messags.filter(m => moment(m.dateCreated).isAfter(messages[0].dateCreated));
    } else {
      smsMessages = this.messags;
    }

    // Remove loaded messages if lastLoadedMessage provided
    if (lastLoadedMessage) {
      const removedLoadedMessages = smsMessages.filter(m => moment(m.dateCreated).isBefore(lastLoadedMessage.dateCreated));
      return this.sortMessages([...messages, ...removedLoadedMessages]);
    }

    return this.sortMessages([...messages, ...smsMessages]);
  }

  sortMessages(messages: any[]) {
    return sortBy(messages, m => {
      if (m.isScheduled) { return moment(m.schedule).format(); }
      if (m.dateSent) { return moment(m.dateSent).format(); }
      return moment(m.dateCreated).format();
    });
  }

}
