import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { DashboardActions } from './../dashboard/dashboard.action-types';


@Injectable()
export class IntegrationsInterceptor implements HttpInterceptor {

  constructor(private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req)
      .pipe(
        map(evt => {
          if (evt instanceof HttpResponse) {
            if (evt.headers.get('Revoke-Integrations')) {
              const integrationsHeader = evt.headers.get('Revoke-Integrations');
              const integrationsToCancel = integrationsHeader.split(', ');
              this.store.dispatch(DashboardActions.removeIntegration({integrations: integrationsToCancel}));
              // if (integrationsToCancel.includes('googleCalendar')) {
              // TODO remove syncedCalendar from calendars in cache
              // }
            }
          }
          return evt;
        })
      );
  }
}
