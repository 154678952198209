<div fxLayoutAlign="space-between" >
  <h1 fxFlex style="text-align: center;" mat-dialog-title>{{data.name}}</h1>

  <div fxFlex="100px" fxLayoutAlign="end">
    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onDownloadFile()">Download</button>
      </mat-menu>

      <button color="primary" style="margin-bottom: 26px;" mat-icon-button (click)="onNoClick()" matTooltip="Close Preview">
        <mat-icon>close</mat-icon>
      </button>
  </div>
</div>

<div mat-dialog-content>

  <ngx-doc-viewer
      *ngIf="fileType === 'doc' && previewFile"
      [url]="previewFile?.url"
      viewer="office"
      style="width:100%;height:68vh;"
      (loaded)="previewLoading = false"
      ></ngx-doc-viewer>

    <pdf-viewer
      *ngIf="fileType === 'pdf' && previewFile"
      [src]="previewFile?.url"
      [render-text]="true"
      [fit-to-page]="true"
      (page-rendered)="previewLoading = false"
      [external-link-target]="'blank'"
      style="display: block;"
    ></pdf-viewer>

    <div *ngIf="fileType && fileType === 'image'">
      <img style="width: 100%;" [src]="previewFile?.url" [alt]="previewFile?.name">
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="previewFile && previewLoading">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayoutAlign="center center" style="width: 150px; height: 55vh;">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>

</div>
