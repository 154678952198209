import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalComponent, ModalData } from './components/modal/modal.component';
import { ModalLoaderComponent } from './components/modal-loader/modal-loader.component';
import { PreviewFileModalComponent } from './components/preview-file-modal/preview-file-modal.component';
import { ApplicantFile } from './../interfaces/applicant.interface';
import { LicenseErrorData, ModalLicenseErrComponent } from './components/modal-license-err/modal-license-err.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public globalLoaderState = new Subject<boolean>();

  private licenseIsValid = new Subject<boolean>();

  constructor(private dialog: MatDialog, private http: HttpClient) {}

  modalSimpleMessage(data: ModalData) {
    const dialogRef = this.dialog.open(ModalComponent, { data, minWidth: '300px', maxWidth: '450px' });
    return dialogRef.afterClosed();
  }

  showSpinner(): MatDialogRef<any> {
    return this.dialog.open(ModalLoaderComponent, {disableClose: true});
  }

  previewFileModal(data: ApplicantFile, fileEntity: 'applicant' | 'candidate' = 'applicant', candidateId?: string) {
    const previewData = {...data, fileEntity, candidateId};
    return this.dialog.open(PreviewFileModalComponent, { data: previewData, minWidth: '900px', maxWidth: '900px' });
  }

  licenseError(data: LicenseErrorData) {
    const dialogRef = this.dialog.open(ModalLicenseErrComponent, {data, disableClose: true, width: '450px'});
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe(() => this.licenseIsValid.next(false));
  }

  licenseCheck(license: 'user' | 'client' | 'territory' | 'phone' | 'phone-number', redirectBack = true) { // 'client' check 'company' license
    const params = new HttpParams().append('redirect', redirectBack.toString());

    this.http.get(`${environment.serverURL}/api/${license}/license/check`, {params})
      .pipe(take(1))
      .subscribe(() => this.licenseIsValid.next(true));

    return this.licenseIsValid;
  }

}
