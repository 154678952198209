import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Client as ConversationsClient } from '@twilio/conversations';
import { Howl } from 'howler';
import { previewApplicant } from './../dashboard/main/main.selectors';
import { selectedCompanyPhoneNumbers } from './../dashboard/store/phone/phone.selectors';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, distinctUntilChanged, exhaustMap, filter, tap, first, switchMap, catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { DashboardService } from '../dashboard/dashboard.service';
import { ApplicantEntityService } from './../dashboard/store/entity-services/applicant-entity.service';
import { ClosedConversations } from './classes/closed-conversations';
import { ChatSmsMessages } from './classes/chat-sms-messages';
import { MainActions } from '../dashboard/main/main.action-types';
import { user } from '../auth/auth.selectors';
import { isEmpty } from 'lodash';
import { User } from '../interfaces/user.model';
import { PhoneNumber } from './../dashboard/admin/phone/interfaces/phone-number.interface';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { Paginator } from '@twilio/conversations/lib/interfaces/paginator';
import { Participant } from '@twilio/conversations/lib/participant';
import { SmsMessage } from './../interfaces/sms-message.interface';
import { TextTemplate } from '../interfaces/text-template.interface';
import { Applicant } from './../interfaces/applicant.interface';

interface PrevPageOpt {
  scrollBehaviour: 'smoothScrollIntoView' | 'scrollIntoView' | 'none';
}

interface SmsBodyObject {
  applicantId: string;
  clientId: string;
  clientTerritoryId: string;
  isScheduled: boolean;
  fromPhoneNumberId: string;
  schedule: string;
  timezone: string;
  templateId?: string;
  body?: string;
  bulk?: SmsBodyObject[];
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  applicantEntityService: ApplicantEntityService;

  user: User;

  private chatWindowIsOpen = false;

  private applicantId: string;

  private phoneNumber: PhoneNumber;

  private conversationsClient: ConversationsClient;

  // Active conversation
  private conversations: any = [];
  private activeConversation: Conversation;
  activeConversationExist$ = new BehaviorSubject<boolean>(false);
  lastReadMessageIndex = new BehaviorSubject<number>(0);
  private paginationConversationSid: string; // Conversation sid which messages currently loaded in paginator

  private activeConversationParticipants: Participant[] = [];
  iAmAParticipant = new BehaviorSubject<boolean>(false);

  participantsNameMap = new BehaviorSubject<any>({});
  conversationNameMap$ = new BehaviorSubject<any>({});

  messages = new BehaviorSubject<any[]>([]);
  smsMessages: ChatSmsMessages;
  hasMessages$ = new BehaviorSubject<boolean>(false);

  // Conversations list
  conversationList$ = new BehaviorSubject<Conversation[]>([]);
  conversationPaginator: Paginator<Conversation>;
  private preventConversationAddedEvent = true; // Preventing conversationAdded event on client

  status = new BehaviorSubject<'default' | 'success' | 'warning' | 'error'>(null);
  statusString = new BehaviorSubject<string>(null);
  conversationsReady$ = new BehaviorSubject<boolean>(false);

  isLoading = new BehaviorSubject<boolean>(false);

  private sound;

  private paginator: Paginator<any>;
  private messagesPerPage = 10;

  // Text Templates
  textTemplatesLoaded = false;
  textTemplatesClientTerritoryId: string;
  textTemplates$ = new BehaviorSubject<TextTemplate[]>([]);
  textTemplatesLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    private dashboardService: DashboardService,
    private http: HttpClient,
  ) {
    this.sound = new Howl({src: ['assets/notification.mp3']});
  }

  initChat() {
    this.store
      .pipe(
        first(),
        select(user),
        filter(res => !!res),
        distinctUntilChanged((cur, prev) => cur._id === prev._id),
        tap(selectedUser => this.user = selectedUser),
        exhaustMap(() => this.getToken),
      )
      .subscribe(token => this.initConversations(token));

    this.store.select(previewApplicant)
      .subscribe(applicant => this.chatWindowIsOpen = !!applicant);

    // disable / enable chat based on allowed phone number
    this.store
      .pipe(
        select(selectedCompanyPhoneNumbers),
      )
      .subscribe(phoneNumbers => {
        if (phoneNumbers.length > 0) {
          this.conversationsReady$.next(true);
        } else {
          this.conversationsReady$.next(false);
        }
      });
  }

  setApplicantEntityService(applicantEntityService: ApplicantEntityService) {
    this.applicantEntityService = applicantEntityService;
  }

  setPhoneNumber(phone: PhoneNumber) {
    if (phone) { this.phoneNumber = phone; }
  }

  private get getToken() {
    return this.dashboardService.getTwilioToken(this.user._id);
  }

  private updateToken() {
    this.getToken
      .pipe(take(1))
      .subscribe(token => this.conversationsClient.updateToken(token));
  }

  private reset() {
    this.applicantId = null;
    this.conversations = [];
    this.activeConversation = null;
    this.paginator = null;
    this.messages.next([]);
    this.hasMessages$.next(false);
    this.isLoading.next(true);
    this.iAmAParticipant.next(false);
    this.preventConversationAddedEvent = false;
  }

  async loadConversations(applicantId: string, conversationSids: string[]) {
    this.reset();

    this.smsMessages = new ChatSmsMessages(applicantId, this.http);
    await this.smsMessages.loadAllMessages();

    const conversations: Conversation[] = [];
    let activeConversation: Conversation;
    let lastReadMessageIndex: number;

    this.preventConversationAddedEvent = true; // preventing conversationAdded event

    for (const conversationSid of conversationSids) {
      try {
        // User can use chat (conversationsClient is active)
        if (this.conversationsReady$.value) {

          const conversation = await this.conversationsClient.getConversationBySid(conversationSid);
          conversations.push(conversation);

          if (conversation.state.current === 'active' || conversation.state.current === 'inactive') {
            activeConversation = conversation; // this line is needed or code breaks
            lastReadMessageIndex = conversation.lastReadMessageIndex;
            activeConversation = await this.loadMessages(activeConversation);
          }
        } else {
          // User can NOT use chat (conversationsClient is inactive)
          const conversation = new ClosedConversations(conversationSid, this.http);
          conversations.push(conversation as any);
        }
      } catch (error) {
        if (error.message === `Conversation with SID ${conversationSid} is not found.`) {
          const conversation = new ClosedConversations(conversationSid, this.http);
          (conversations as any).push(conversation);
        } else {
          console.log(conversationSid, error);
        }
      }
    }
    this.preventConversationAddedEvent = false; // allow conversationAdded to fire

    // Applicant doesn't have conversations yet
    if (conversations.length === 0) {
      this.messages.next(this.smsMessages.messags);
      if (this.messages.value.length > 0) {
        this.hasMessages$.next(true);
      } else {
        this.hasMessages$.next(false);
      }
      this.isLoading.next(false);
    }

    // All conversations are closed or User has no access to active conversation. Loading messages
    if (conversations.length > 0 && !activeConversation) {
      const lastConversation = conversations[conversations.length - 1];
      this.paginationConversationSid = lastConversation.sid;
      // Get participants
      this.getParticipantsByConversationSid(lastConversation.sid);
      try {
        // Get Messages
        const paginator = await lastConversation.getMessages(this.messagesPerPage);

        this.paginator = paginator;
        this.messages.next(this.smsMessages.mergeMessages(paginator.items));
        this.hasMessages$.next(true);

        this.isLoading.next(false);
      } catch (error) {}
    }

    this.applicantId = applicantId;
    this.activeConversation = activeConversation;
    this.lastReadMessageIndex.next(lastReadMessageIndex);
    this.conversations = conversations;
    this.activeConversationExist$.next(!!this.activeConversation);

    this.loadPreviousMessagesToFillPageSize({scrollBehaviour: 'none'});
  }

  private async loadMessages(activeConversation: Conversation): Promise<Conversation> {
    // Get participants and their names
    this.getParticipantsByConversationSid(activeConversation.sid);

    // Keep track of pagination's conversation
    this.paginationConversationSid = activeConversation.sid;

    try {
      this.paginator = await activeConversation.getMessages(this.messagesPerPage);

      this.messages.next(this.smsMessages.mergeMessages(this.paginator.items));
      this.hasMessages$.next(true);

      // Get active conversation participants
      const participants = await activeConversation.getParticipants();
      this.activeConversationParticipants = participants;
      if (this.activeConversationParticipants.findIndex(p => p.identity === this.user._id) > -1) {
        this.iAmAParticipant.next(true);
      } else {
        this.iAmAParticipant.next(false);
      }

      // Update read progress
      if (this.iAmAParticipant.value) {
        activeConversation.updateLastReadMessageIndex(this.paginator.items[this.paginator.items.length - 1].index);
      }

      this.isLoading.next(false);
      return activeConversation;
    } catch (error) {}

  }

  // Get current conversation index
  private get currentConvIndex() {
    return this.conversations.findIndex(c => c.sid === this.paginationConversationSid);
  }

  private async loadPreviousMessagesToFillPageSize(opt: PrevPageOpt) {
    // No previous conversations, load all past messages
    if (!this.conversations[this.currentConvIndex - 1]) {
      const prevSmsMessages = this.smsMessages.mergeMessages([], this.messages.value[0], true);
      if (prevSmsMessages.length > 0) {
        const preventScrollingMessages = prevSmsMessages.map(m => ({...m, noScroll: true}));
        this.messages.next([...preventScrollingMessages, ...this.messages.value]);
      }
    }

    // Load previous conversation if current conversation has not enough messages
    while (this.messages.value.length < this.messagesPerPage && this.conversations[this.currentConvIndex - 1]) {
      await this.loadPrevPage(opt);
    }
    return;
  }

  async loadPrevPage(opt: PrevPageOpt = {scrollBehaviour: 'scrollIntoView'}): Promise<void> {
    if (this.paginator?.hasPrevPage) {

      this.isLoading.next(true);

      this.paginator = await this.paginator.prevPage();

      const mergedMessages = this.smsMessages.mergeMessages(this.paginator.items, this.messages.value[0]);

      // Apply scroll behaviour
      const preventScrollingMessages = mergedMessages.map(m => {
        if (opt.scrollBehaviour === 'scrollIntoView') { m.scrollIntoView = true; }
        if (opt.scrollBehaviour === 'none') { m.noScroll = true; }
        return m;
      });
      this.messages.next([...preventScrollingMessages, ...this.messages.value]);
      this.isLoading.next(false);

    } else {
      // Look for previous conversation
      if (!this.conversations[this.currentConvIndex - 1]) {
        // Load all sms messages if any
        const prevSmsMessages = this.smsMessages.mergeMessages([], this.messages.value[0], true);
        if (prevSmsMessages.length > 0) {
          const preventScrollingMessages = prevSmsMessages.map(m => {
            if (opt.scrollBehaviour === 'scrollIntoView') { m.scrollIntoView = true; }
            if (opt.scrollBehaviour === 'none') { m.noScroll = true; }
            return m;
          });
          this.messages.next([...preventScrollingMessages, ...this.messages.value]);
        }
        return;
      }
      // Load messages
      const previousConversatoin = this.conversations[this.currentConvIndex - 1];
      this.paginationConversationSid = previousConversatoin.sid;
      // Get participants
      this.getParticipantsByConversationSid(previousConversatoin.sid);

      try {
        this.isLoading.next(true);

        this.paginator = await previousConversatoin.getMessages(this.messagesPerPage);

        const prevPageMessagesMerged = this.smsMessages.mergeMessages(this.paginator.items, this.messages.value[0]);

        // Apply scroll behaviour
        const preventScrollingMessages = prevPageMessagesMerged.map(m => {
          if (opt.scrollBehaviour === 'scrollIntoView') { m.scrollIntoView = true; }
          if (opt.scrollBehaviour === 'none') { m.noScroll = true; }
          return m;
        });

        this.messages.next([...preventScrollingMessages, ...this.messages.value]);
        this.isLoading.next(false);

      } catch (error) {
        console.log(error);
      }
    }
    return;
  }

  private async initConversations(token: string) {
    if (this.conversationsClient) { this.conversationsClient.removeAllListeners(); }

    this.conversationsClient = await ConversationsClient.create(token);

    this.conversationsClient.on('connectionStateChanged', state => {
      switch (state) {
        case 'connecting':
          this.status.next('default');
          this.statusString.next('Connecting...');
          this.conversationsReady$.next(false);
          break;
        case 'connected':
          this.status.next('success');
          this.statusString.next('Online');
          this.conversationsReady$.next(true);
          this.loadConversationList();
          break;
        case 'disconnecting':
          this.status.next('default');
          this.statusString.next('Disconnecting...');
          this.conversationsReady$.next(false);
          break;
        case 'disconnected':
          this.status.next('warning');
          this.statusString.next('Disconnected');
          this.conversationsReady$.next(false);
          break;
        case 'denied':
          this.status.next('error');
          this.statusString.next('Failed to connect');
          this.conversationsReady$.next(false);
          break;
      }
    });

    this.conversationsClient.on('conversationAdded', conversation => {
      if (this.preventConversationAddedEvent) { return; }

      const { attributes } = conversation;

      this.sound.play();

      this.applicantEntityService.getByKey(attributes.applicantId)
        .pipe(take(1) )
        .subscribe(async applicant => {
          // Check if chat is open
          if (this.chatWindowIsOpen && applicant._id === this.applicantId) {
            this.activeConversation = conversation;
            // Update last read index
            const index = conversation.lastMessage.index;
            this.activeConversation.updateLastReadMessageIndex(index).catch(console.log);
            this.lastReadMessageIndex.next(index);
            this.conversationList$.next([conversation, ...this.conversationList$.value]);
            this.updateConversationNames([conversation]);
            // Update view
            this.store.dispatch(MainActions.previewApplicant({applicant}));
          } else {
            try {
              // Update conversations list and unread messages
              (conversation as any).unreadMessagesCount = await conversation.getUnreadMessagesCount();
              // Adding unread messages for new conversations
              if ((conversation as any).unreadMessagesCount === null) { (conversation as any).unreadMessagesCount = 1; }
              this.conversationList$.next([conversation, ...this.conversationList$.value]);
              this.updateConversationNames([conversation]);
            } catch (error) { console.log(error); }
          }
        });
    });


    this.conversationsClient.on('conversationUpdated', async res => {
      let skipUnreadUpdate = false; // don't get unread messages in open conversation

      const { conversation, updateReasons } = res;

      // Update active conversation
      if (this.activeConversation && this.activeConversation.sid === conversation.sid && this.iAmAParticipant.value) {
        this.activeConversation = conversation;
        if (updateReasons.includes('lastMessage')) { // New message arrived - update last read index
          const index = conversation.lastMessage.index;
          this.activeConversation.updateLastReadMessageIndex(index).catch(console.log);
          skipUnreadUpdate = true;
          this.lastReadMessageIndex.next(index);
        }
      }

      // Update conversatoin list
      if (updateReasons.includes('lastMessage') || updateReasons.includes('lastReadMessageIndex')) {
        // Check unread messages only if conversation is not open
        if (skipUnreadUpdate || conversation.lastReadMessageIndex === conversation.lastMessage.index) {
          (conversation as any).unreadMessagesCount = 0;
        } else {
          (conversation as any).unreadMessagesCount = await conversation.getUnreadMessagesCount();
          // Fixing autocreation unread messages issue
          if ((conversation as any).unreadMessagesCount === null) { (conversation as any).unreadMessagesCount = 1; }
        }
        this.updateOneConversationOnConversationList(conversation);
      }
    });

    this.conversationsClient.on('messageAdded', async message => {
      if (message.author !== this.user._id) { this.sound.play(); }

      if (this.activeConversation?.sid === message.conversation.sid) {

        // Add message to messages
        const messagesList = this.messages.value;
        const index = messagesList.findIndex(m => m.sid === message.sid);

        if (index > -1) { // replace old message
          messagesList.splice(index, 1, message);
          this.messages.next([...messagesList]);
        } else { // insert new message
          this.messages.next([...messagesList, message]);
        }

        // Update conversation list
        (this.activeConversation as any).unreadMessagesCount = 0;
        this.updateOneConversationOnConversationList(this.activeConversation);
      }
    });

    this.conversationsClient.on('messageRemoved', message => {
      this.messages.next(this.messages.value.filter(m => m.sid !== message.sid));
    });

    this.conversationsClient.on('participantJoined', participant => {
      this.activeConversationParticipants.push(participant);
      if (participant.identity === this.user._id) { this.iAmAParticipant.next(true); }
      this.getParticipantsByConversationSid(participant.conversation.sid); // Update participants names
    });

    this.conversationsClient.on('participantLeft', participant => {
      this.activeConversationParticipants = this.activeConversationParticipants.filter(p => p.sid !== participant.sid);
      if (participant.identity === this.user._id) { this.iAmAParticipant.next(false); }
    });

    this.conversationsClient.on('tokenAboutToExpire', this.updateToken.bind(this));
    this.conversationsClient.on('tokenExpired', this.updateToken.bind(this));
  }

  shutdown() {
    this.reset();
    this.conversationsClient?.shutdown();
    this.conversationsReady$.next(false);
  }

  async newMessage(message: string) {
    if (!this.activeConversation) { // Conversation doesn't exist
      this.sendSingleSmsMessage(message);
    } else if (this.iAmAParticipant.value) {
      this.activeConversation.sendMessage(message); // Conversation is active
    } else {
      try { // Conversation is active, but Participant is not in this conversation
        await this.joinConversation();
        this.activeConversation.sendMessage(message);
      } catch (error) {
       console.log(error);
      }
    }
  }

  private sendSingleSmsMessage(message: string) {
    this.isLoading.next(true);

    const body: Partial<SmsBodyObject> = {
      applicantId: this.applicantId,
      body: message,
      fromPhoneNumberId: this.phoneNumber._id
    };

    this.store
        .pipe(
          select(previewApplicant),
          filter(applicant => !!applicant),
          switchMap(applicant => this.http.post<SmsMessage>(environment.serverURL + '/api/phone/sms/new', {...body, applicantId: this.applicantId, clientId: applicant.clientId, clientTerritoryId: applicant.clientTerritoryId})),
          take(1),
          catchError(error => {
            this.isLoading.next(false);
            throw error;
          })
        )
        .subscribe(sentMessage => {
          const formattedMessage = this.smsMessages.formatMessage(sentMessage);
          this.messages.next(this.smsMessages.sortMessages([...this.messages.value, formattedMessage]));
          this.isLoading.next(false);
        });
  }

  deleteScheduledSmsMessage(messageId: string) {
    this.isLoading.next(true);

    this.http.delete(environment.serverURL + '/api/phone/sms/' + messageId + '/scheduled')
      .pipe(
        take(1),
        catchError(error => {
          this.isLoading.next(false);
          throw error;
        })
      )
      .subscribe(() => {
        const messages = this.messages.value.filter(m => m.id !== messageId);
        this.messages.next(messages);
        this.isLoading.next(false);
      });
  }

  private async getParticipantsByConversationSid(conversationSid: string) {
    let participants = [];
    const identities = [];
    const phoneNumbers = [];

    try {
      participants = await this.http.get<any[]>(environment.serverURL + '/api/conversation/' + conversationSid + '/participants').toPromise();
    } catch (error) {
      console.log(error);
    }

    // Add new participants
    for (const participant of participants) {
      let skip = false;

      if (!isEmpty(this.participantsNameMap.value)) {
        Object.keys(this.participantsNameMap.value).forEach(key => {
          if (participant.messagingBinding?.address === key || participant.identity === key) { skip = true; }
        });
      }

      // Bundle identities and phoneNumbers
      if (!skip) {
        if (participant.identity) { identities.push(participant.identity); }
        if (participant.messagingBinding?.address) { phoneNumbers.push(participant.messagingBinding.address); }
      }
    }

    // Get names
    if (identities.length > 0 || phoneNumbers.length > 0) {
      this.store
        .pipe(
          select(previewApplicant),
          filter(applicant => !!applicant),
          switchMap(applicant => this.http.post(environment.serverURL + '/api/conversation/' + conversationSid + '/participants/names', {identities, phoneNumbers, clientId: applicant.clientId, clientTerritoryId: applicant.clientTerritoryId})),
          take(1),
        )
        .subscribe(names => this.participantsNameMap.next({...this.participantsNameMap.value, ...names}));
    }
  }

  async joinConversation() {
    try {
      this.isLoading.next(true);
      await this.http.post(environment.serverURL + '/api/conversation/' + this.activeConversation.sid + '/join', {phoneNumberId: this.phoneNumber._id}).toPromise();

      this.iAmAParticipant.next(true);

      // Update active conversation
      const activeConversation = await this.conversationsClient.getConversationBySid(this.activeConversation.sid);
      this.activeConversation = activeConversation;

      // Update conversation list
      this.conversationList$.next([activeConversation, ...this.conversationList$.value]);
      this.updateConversationNames([activeConversation]);

      // Update active participants
      const participants = await this.activeConversation.getParticipants();
      this.activeConversationParticipants = participants;
      this.iAmAParticipant.next(true);

      this.getParticipantsByConversationSid(this.activeConversation.sid);

      // Update messages
      this.messages.next(this.smsMessages.messags);
      this.hasMessages$.next(false);
      this.activeConversation = await this.loadMessages(this.activeConversation);
      this.loadPreviousMessagesToFillPageSize({scrollBehaviour: 'none'});
    } catch (error) {
      console.log(error);
    }
  }

  async leaveConversation() {
    try {
      const { sid: conversationSid } = this.activeConversation;
      const { sid: participantSid } = await this.activeConversation.getParticipantByIdentity(this.user._id);

      const params = new HttpParams().append('conversationSid', conversationSid).append('participantSid', participantSid);

      this.http.delete<boolean>(environment.serverURL + `/api/conversation/${conversationSid}/participant/${participantSid}/remove`, {params})
        .pipe(take(1), filter(res => res))
        .subscribe(() => {
          this.iAmAParticipant.next(false);

          // clear messages
          this.messages.next([]);

          // Update conversation list
          const conversationList = this.conversationList$.value;
          const index = conversationList.findIndex(c => c.sid === this.activeConversation.sid);
          conversationList.splice(index, 1);
          this.conversationList$.next([...conversationList]);
        });

    } catch (error) {
      console.log(error);
    }
  }

  async deleteConversation() {
    try {
      const { sid: conversationSid } = this.activeConversation;
      const applicantId = this.applicantId;

      this.isLoading.next(true);

      this.http.delete<Applicant>(environment.serverURL + `/api/conversation/${conversationSid}/applicant/${applicantId}`)
        .pipe(
          take(1),
          catchError(error => {
            this.isLoading.next(false);
            throw error;
          })
        )
        .subscribe(applicant => {
          this.applicantEntityService.updateOneInCache(applicant);
          if (this.applicantId === applicant._id) { this.store.dispatch(MainActions.previewApplicant({applicant})); }
          this.conversationList$.next(this.conversationList$.value.filter(c => c.sid !== conversationSid));
          this.reset();
        });
    } catch (error) {
      console.log(error);
    }
  }

  async closeConversation() {
    this.http.get(environment.serverURL + '/api/conversation/' + this.activeConversation.sid + '/close').pipe(take(1)).subscribe();

    // Update conversation list
    const conversationList = this.conversationList$.value;
    const index = conversationList.findIndex(c => c.sid === this.activeConversation.sid);
    conversationList.splice(index, 1);
    this.conversationList$.next([...conversationList]);

    this.activeConversation = null;
    this.activeConversationExist$.next(false);
  }

  removeActiveConversation() {
    this.activeConversation = null;
  }

  private async loadConversationList() {
    // Reset
    this.conversationList$.next([]);
    this.preventConversationAddedEvent = true;

    try {
      this.conversationPaginator = await this.conversationsClient.getSubscribedConversations();

      // Get unread messages
      for (const conversation of this.conversationPaginator.items) {
        // Skipping active conversation, coz all the messages will be read
        if (this.activeConversation && this.activeConversation.sid === conversation.sid) {
          (conversation as any).unreadMessagesCount = 0;
        } else {
          (conversation as any).unreadMessagesCount = await conversation.getUnreadMessagesCount();
          if ((conversation as any).unreadMessagesCount === null) { (conversation as any).unreadMessagesCount = 1; }
        }
      }
    } catch (error) {
      console.log(error);
    }
    // Get names from Conversations;
    this.updateConversationNames(this.conversationPaginator.items);
    this.conversationList$.next(this.conversationPaginator.items);
    this.preventConversationAddedEvent = false;
  }

  private updateOneConversationOnConversationList(conversation: Conversation) {
    const conversationList = this.conversationList$.value;
    const index = conversationList.findIndex(c => c.sid === conversation.sid);

    if (index > -1) { // replace old conversation
      conversationList.splice(index, 1, conversation);
      this.conversationList$.next([...conversationList]);
    } else { // insert new conversation on top of the list
      this.conversationList$.next([conversation, ...conversationList]);
      this.updateConversationNames([conversation]);
    }
  }

  private async updateConversationNames(conversations: Conversation[]) {
    const applicantIds = conversations.map(c => (c.attributes as any).applicantId as string);
    const newApplicantIds = [];

    for (const applicantId of applicantIds) {
      // Check if name already exist
      if (Object.keys(this.conversationNameMap$.value).findIndex(key => key === applicantId) > -1) { continue; }
      newApplicantIds.push(applicantId);
    }

    if (newApplicantIds.length > 0) {
      const names = await this.http.post(environment.serverURL + '/api/conversation/names', {newApplicantIds}).toPromise();
      this.conversationNameMap$.next({...this.conversationNameMap$.value, ...names});
    }
  }

  async conversationNextPage() {
    if (!this.conversationPaginator.hasNextPage) { return; }

    try {
      this.conversationPaginator = await this.conversationPaginator.nextPage();
      this.updateConversationNames(this.conversationPaginator.items);
    } catch (error) {
      console.log(error);
    }

    // Get unread messages
    for (const conversation of this.conversationPaginator.items) {
      (conversation as any).unreadMessagesCount = await conversation.getUnreadMessagesCount();
    }

    this.conversationList$.next([...this.conversationList$.value, ...this.conversationPaginator.items]);
  }

  loadTextTemplates(clientId: string, clientTerritoryId: string) {
    this.textTemplatesLoading$.next(true);
    this.textTemplates$.next([]);
    const params = new HttpParams().append('clientId', clientId).append('clientTerritoryId', clientTerritoryId);

    this.http.get<{entities: TextTemplate[], clientId: string, clientTerritoryId: string}>(environment.serverURL + '/api/text-templates', {params})
      .pipe(
        take(1),
        catchError(error => {
          this.textTemplatesLoading$.next(false);
          throw error;
        })
      )
      .subscribe(res => {
        this.textTemplatesClientTerritoryId = res.clientTerritoryId;
        this.textTemplates$.next(res.entities);
        this.textTemplatesLoading$.next(false);
      });
  }

  makeTextTemplate(textTemplateId: string, applicantId: string): Observable<string> {
    return this.http.get<{template: string}>(environment.serverURL + `/api/text-template-make/${textTemplateId}/applicant/${applicantId}`)
      .pipe(map(res => res.template));
  }
}
