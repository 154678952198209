import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Client } from '../../../interfaces/client.interface';

@Injectable({providedIn: 'root'})
export class ClientEntityService extends EntityCollectionServiceBase<Client> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('client', serviceElementsFactory);
  }

}
