import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appScrollListener]'
})
export class AppScrollListenerDirective {

  @Output() scrolledToTop = new EventEmitter<void>();
  @Output() scrolledToBottom = new EventEmitter<void>();

  mouseover: boolean;

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    if (!this.mouseover) { return; }

    const element = event.target;
    const elementRec = element.getBoundingClientRect();

    if (element.scrollTop === 0) {
      this.scrolledToTop.emit();
    } else if (element.scrollHeight - elementRec.height - 1 <= element.scrollTop) {
      this.scrolledToBottom.emit();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.mouseover = true;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.mouseover = false;
  }

}
