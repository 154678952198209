import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { selectedClient } from './../../dashboard.selectors';
import { ReplaySubject, Subject } from 'rxjs';
import { tap, filter, takeUntil, map, switchMap } from 'rxjs/operators';
import { ClientEntityService } from '../../store/entity-services/client-entity.service';
import { DashboardState } from '../../dashboard.reducer';
import { DashboardActions } from '../../dashboard.action-types';
import { Client, ClientTerritoty } from './../../../interfaces/client.interface';
import { MainActions } from '../../main/main.action-types';

@Component({
  selector: 'app-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss']
})
export class SelectClientComponent implements OnInit, OnDestroy {

  _onDestroy = new Subject<void>();

  selectedClientId: string;
  selectedTerritoryId: string;

  clientForm: UntypedFormGroup;

  // Clients Select
  clientsFilterCtrl: UntypedFormControl = new UntypedFormControl();
  clients: Client[] = [];
  filteredClients$: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(1);

  // Territory Select
  territoriesFilterCtrl: UntypedFormControl = new UntypedFormControl();
  territories: ClientTerritoty[] = [];
  filteredTerritories$: ReplaySubject<ClientTerritoty[]> = new ReplaySubject<ClientTerritoty[]>(1);

  constructor(
    public dialogRef: MatDialogRef<SelectClientComponent>,
    private clientService: ClientEntityService,
    private store: Store<DashboardState>,
  ) { }

  ngOnInit(): void {
    this.clientForm = new UntypedFormGroup({
      client: new UntypedFormControl(null, Validators.required),
      territory: new UntypedFormControl(null, Validators.required),
    });

    this.clientService.loaded$
      .pipe(
        takeUntil(this._onDestroy),
        tap(isLoaded => {
          if (!isLoaded) { this.clientService.getAll(); }
        }),
        switchMap(() => this.clientService.entities$.pipe(map(clients => clients.filter(c => c.status === 'Active')))),
        tap(clientsList => {
          this.clients = clientsList;
          this.filteredClients$.next(clientsList);
        })
      )
      .subscribe();

    // Clients dynamic search
    this.clientsFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.filterClients());

    // Territories dynamic search
    this.territoriesFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.filterTerritories());

    // Select Client
    this.store.pipe(
      takeUntil(this._onDestroy),
      select(selectedClient),
      filter(client => !!client),
      tap(selectedCl => {
        this.updateForm(selectedCl);
        this.selectedClientId = selectedCl._id;
        this.selectedTerritoryId = selectedCl.selectedTerritory._id;

        this.clientForm.get('territory').setValue(selectedCl.territories[0]._id);
        this.territories = selectedCl.territories;
        this.filteredTerritories$.next(selectedCl.territories);
      })
    )
    .subscribe();

    this.loadTerritories();
  }

  updateForm(client: Client) {
    this.clientForm.get('client').patchValue(client._id);
    this.clientForm.get('territory').patchValue(client.selectedTerritory._id);
  }

  loadTerritories() {
    this.clientForm.get('client').valueChanges
      .pipe(
        switchMap(selectedClientId => this.clientService.entities$
          .pipe(
            takeUntil(this._onDestroy),
            map(clientsList => {
              const client = clientsList.find(c => c._id === selectedClientId);
              return client.territories;
            })
          )
        ),
        tap(territories => {
          this.territories = territories;
          this.filteredTerritories$.next(territories);
          this.clientForm.get('territory').setValue(territories[0]._id);
        })
      )
      .subscribe();
  }


  filterClients() {
    let search = this.clientsFilterCtrl.value;
    if (!search) {
      this.filteredClients$.next(this.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredClients$.next(
      this.clients.filter(client => client.name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterTerritories() {
    let search = this.territoriesFilterCtrl.value;
    if (!search) {
      this.filteredTerritories$.next(this.territories.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTerritories$.next(
      this.territories.filter(t => t.name.toLowerCase().indexOf(search) > -1)
    );
  }

  onSubmit() {
    const clientId = this.clientForm.value.client;
    const territoryId = this.clientForm.value.territory;
    if (clientId && territoryId) {
      const client = this.clients.find(c => c._id === clientId);
      const territory = this.territories.find(t => t._id === territoryId);
      const newClient = {...client, selectedTerritory: territory};
      if (this.selectedClientId !== newClient._id || this.selectedTerritoryId !== territoryId) {
        this.store.dispatch(DashboardActions.selectClient({client: newClient}));
        this.store.dispatch(MainActions.previewApplicant({applicant: undefined}));
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
