<h1 style="text-align: center;" mat-dialog-title>Select a company</h1>
<form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Company</mat-label>
      <mat-select formControlName="client">
        <mat-option>
          <ngx-mat-select-search noEntriesFoundLabel="Not found..." placeholderLabel="Search..." [formControl]="clientsFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let client of filteredClients$ | async" [value]="client._id">
          {{client.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Territory</mat-label>
      <mat-select formControlName="territory">
        <mat-option>
          <ngx-mat-select-search noEntriesFoundLabel="Not found..." placeholderLabel="Search..." [formControl]="territoriesFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let territory of filteredTerritories$ | async" [value]="territory._id">
          {{territory.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" >
    <button type="submit" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Select</button>
  </div>
</form>
