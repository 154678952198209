import { User } from '../../interfaces/user.model';
import { on, createReducer, Action } from '@ngrx/store';
import { AuthActions } from '../action-types';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: User;
}

export const initialAuthState: AuthState = {
  user: undefined
};


const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.loginSuccess, (state, action) => ({...state, user: action.user})),
  on(AuthActions.logout, (state) => ({...state, user: undefined})),
  on(AuthActions.errorLogout, (state) => ({...state, user: undefined})),
  on(AuthActions.updateUser, (state, action) => ({...state, user: action.user})),
);

export function authReducerFunction(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
