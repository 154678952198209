import { createAction, props } from '@ngrx/store';
import { User } from '../interfaces/user.model';

export const login = createAction(
  '[Auth] Login',
  props<{email: string, password: string}>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{user: User}>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{error: any}>()
);

export const logout = createAction(
  '[Top Profile] Logout'
);

export const errorLogout = createAction(
  '[Login Error] Logout'
);

export const updateUser = createAction(
  '[Auth Interceptor] Update User',
  props<{user: User}>()
);
