import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './../../../reducers/index';
import { DashboardState } from './../../dashboard.reducer';
import { PhoneState } from './phone.reducer';

const selectPhoneState = createFeatureSelector<AppState, PhoneState>('phone');

const selectDashboard = createFeatureSelector<AppState, DashboardState>('dashboard');

export const userPhoneNumbers = createSelector(
  selectPhoneState,
  state => state.phoneNumbers,
);

const selectedClient = createSelector(
  selectDashboard,
  state => state.client ? state.client : null
);

export const selectedCompanyPhoneNumbers = createSelector(
  userPhoneNumbers,
  selectedClient,
  (phoneNumbers, client) => {
    if (phoneNumbers && phoneNumbers.length > 0 && client) {
      return phoneNumbers.filter(p => p.clientId === client._id);
    } else {
      return [];
    }
  }
);
