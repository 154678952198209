import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { tap, map, first, filter } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { AppState } from './../reducers/index';
import { user } from '../auth/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private store: Store<AppState>, private router: Router) {

  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  //   return this.store.pipe(
  //     select(user),
  //     map(u => {
  //       if (u) {
  //         if (route.data.roles && route.data.roles.indexOf(u.role) === -1 && u.status === 'Active') {
  //           // role not authorised so redirect to home page
  //           this.router.navigate(['/']);
  //           return false;
  //         } else if (u.status === 'Inactive') {
  //           this.router.navigate(['/login']);
  //           return false;
  //         } else if (u.status === 'Pending Activation') {
  //           this.router.navigate(['/new']);
  //           return false;
  //         }
  //         // authorised so return true
  //         return true;
  //       }
  //       // not logged in so redirect to login page with the return url
  //       this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  //       return false;
  //     })
  //   );
  // }

  canLoad(route: Route): Observable<boolean> {
    return this.store.pipe(
      select(user),
      map(u => {
        if (u) {
          if (route.data.roles && route.data.roles.indexOf(u.role) === -1 && u.status === 'Active') {
            // role not authorised so redirect to home page
            this.router.navigate(['/']);
            return false;
          } else if (u.status === 'Inactive') {
            this.router.navigate(['/login']);
            return false;
          } else if (u.status === 'Pending Activation') {
            this.router.navigate(['/new', u._id]);
            return false;
          }
          // authorised so return true
          return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
      }),
      filter(res => !!res),
      first()
    );
  }

}
