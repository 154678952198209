import { createAction, props } from '@ngrx/store';
import { PhoneNumber } from './../../admin/phone/interfaces/phone-number.interface';

export const loadPhones = createAction(
  '[Phone] Load Phones'
);

export const loadPhonesSuccess = createAction(
  '[Phone] Load Phones Success',
  props<{ phones: PhoneNumber[] }>()
);

export const loadPhonesFailure = createAction(
  '[Phone] Load Phones Failure',
  props<{ error: any }>()
);

export const addPhone = createAction(
  '[Phone] Add Phones',
  props<{ phone: PhoneNumber }>()
);

export const removePhone = createAction(
  '[Phone] Remove Phones',
  props<{ phone: PhoneNumber }>()
);

export const updatePhonesSuccess = createAction(
  '[Phone] Update Phones Success',
  props<{ phones: PhoneNumber[] }>()
);
