import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  private _newVersion$ = new Subject<boolean>();
  private _isSubscribedToUpdates = false;

  newVersion$ = this._newVersion$.asObservable();

  constructor(private updates: SwUpdate) { }

  update() {
    this.checkForUpdates();
  }

  private checkForUpdates() {
    if (this._isSubscribedToUpdates) {
      return;
    }
    this.updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`
          );
          this._newVersion$.next(true);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });
    this._isSubscribedToUpdates = true;
  }
}
