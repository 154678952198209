import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent, ErrorData } from './error/error.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private dialog: MatDialog) { }

  showErrorMessage(data: ErrorData): void {
    this.dialog.open(ErrorComponent, { data, minWidth: '300px', width: '400px'});
  }

}
