import { Action, createReducer, on } from '@ngrx/store';
import { PhoneActions } from './phone.action-types';
import { PhoneNumber } from './../../admin/phone/interfaces/phone-number.interface';


export const phoneFeatureKey = 'phone';

export interface PhoneState {
  phoneNumbers: PhoneNumber[];
}

export const initialState: PhoneState = {
  phoneNumbers: [],
};

export const reducer = createReducer(
  initialState,
  on(PhoneActions.loadPhonesSuccess, (state, action) => ({...state, phoneNumbers: action.phones})),
  on(PhoneActions.loadPhonesFailure, (state) => ({...state, phoneNumbers: []})),
  on(PhoneActions.updatePhonesSuccess, (state, action) => ({...state, phoneNumbers: action.phones})),
);

